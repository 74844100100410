import styled from 'styled-components'

export const InlineWrapper = styled.span<{ size?: number }>`
  display: inline-block;
  font-size: ${({ size = 1 }) => `${size * 0.8}em`};
`

export const FullPageWrapper = styled.span<{ size?: number }>`
  font-size: ${({ size = 1 }) => `${size * 8}em`};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`

export const AbsoluteWrapper = styled.span<{ size?: number }>`
  font-size: ${({ size = 1 }) => `${size * 4}em`};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Placeholder = styled.div<{ height?: string; width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height !== undefined && height};
  width: ${({ width }) => width !== undefined && width};
  flex-shrink: 0;
`
