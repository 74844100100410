import React from 'react'
import { conditionalProp } from 'utils/conditional-prop'

import { ToggleProps } from './interface'
import { ToggleWrapper } from './Toggle.styles'
import { getStylingProps } from './utils'

export const Toggle = (props: ToggleProps) => {
  const { onClick } = props

  return (
    <ToggleWrapper
      id="sdlfjsldk"
      {...conditionalProp(props.isOn, 'data-checked', '')}
      {...getStylingProps(props)}
      {...(onClick instanceof Function ? { onClick } : {})}
    />
  )
}
