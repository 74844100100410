import styled, { css } from 'styled-components'

import { ButtonProps } from './interface'

export const TakeTestLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.primary} !important;
  padding: 0.5rem 1rem;
`

export const ButtonStyled = styled.button<ButtonProps>`
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1;
  border: none;
  outline: none;
  height: 3rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `}

  ${({ short }) =>
    short &&
    css`
      height: 2rem;
    `};

  ${({ mini }) =>
    mini &&
    css`
      padding: 0;
      height: auto;
      padding: 2px 4px;
    `};

  ${({ fluid }) =>
    fluid &&
    css`
      width: 100%;
    `};

  ${({ variant, theme }) =>
    variant === 'secondary' &&
    css`
      background-color: ${theme.palette.background.paper};
      color: ${theme.palette.text.primary};

      &:focus,
      &:hover {
        background-color: ${theme.palette.background.paper};
      }

      &:active {
        background-color: ${theme.palette.background.default};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.palette.disabled.main};
      color: ${({ theme }) => theme.palette.disabled.contrastText};
      cursor: not-allowed;

      &:focus,
      &:hover,
      &:active {
          background-color: ${({ theme }) => theme.palette.disabled.main};
          color: ${({ theme }) => theme.palette.disabled.contrastText};
    `};
`
