import classnames from 'classnames'
import { PaletteMode } from 'components/app-theme-provider/interface'
import { LayoutProps } from 'components/layout/interface'
import { Loader } from 'components/loader'
import { Logo } from 'components/logo'
import { SITE_URL_ORIGIN } from 'constants/site'
import { useAppTheme } from 'hooks/use-app-theme'
import { useIsMobile } from 'hooks/use-is-mobile'
import { Setting } from 'module/setting'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useAuthUser } from 'next-firebase-auth'
import { useEffect, useState } from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import { Button, TakeTestLink } from 'ui-kit/button'
import { statIcon } from 'ui-kit/icons/stat-icon'

import {
  Hamburger,
  LinkItem,
  Links,
  Menu,
  SettingTooltip,
  SignInSignUpLinkButton,
  StyledLink,
  Wrapper,
} from './Nav.styles'

export const Nav = (props: Pick<LayoutProps, 'signOut' | 'uid' | 'authLoading'>) => {
  const { authLoading = false, signOut, uid } = props
  const theme = useAppTheme()
  const router = useRouter()

  // we need serverSyncedHref to update on the client side in useEffect to avoid server-html vs client-html mismatch error. This let us use localhost:3000 on development
  const [serverSyncedHref, setServerSyncedHref] = useState(
    `${SITE_URL_ORIGIN.slice(0, SITE_URL_ORIGIN.length - 1)}${router.asPath}`
  )

  useEffect(() => {
    setServerSyncedHref(window.location.href)
  }, [])

  const isArticlePage = serverSyncedHref.includes('/articles/')

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    interactive: true,
    trigger: 'click',
  })

  const isMobile = useIsMobile()
  const authUser = useAuthUser()

  return (
    <Wrapper>
      <Links>
        {isMobile && (
          <Hamburger>
            ☰
            <Menu>
              <LinkItem>
                <Link prefetch={false} href="/stats/" passHref legacyBehavior>
                  <StyledLink title="Go to Stats page">Stats</StyledLink>
                </Link>
              </LinkItem>
              <LinkItem>
                <Link prefetch={false} href="/lessons/" passHref legacyBehavior>
                  <StyledLink title="Go to Lessons page">Lessons</StyledLink>
                </Link>
              </LinkItem>
            </Menu>
          </Hamburger>
        )}

        {!isMobile && (
          <LinkItem>
            <Link
              // TODO: make sure "gtm-nav-logo" tag is working
              className="gtm-nav-logo"
              title="Go to Typing Practice page"
              prefetch={false}
              href="/typing-practice/"
            >
              <Logo />
            </Link>
          </LinkItem>
        )}

        {/* <LinkItem>
          <StyledLink title="Go to Stats page" href="/typing-mentor-ai-beta/">
            opt in
          </StyledLink>
        </LinkItem> */}

        {isMobile && (
          <LinkItem>
            <Link prefetch={false} href="/typing-practice/" passHref legacyBehavior>
              <a title="Go to Typing Practice page">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="32" height="28">
                  <defs></defs>
                  <path
                    fill="#fff"
                    d="M6.5 12.46A53.65 53.65 0 0 1 6.66 7h18.68a11.7 11.7 0 0 1 .16 3.09v2.36h-1.42c-.11 0-.66 0-.66-.11l-.18-2c0-.07 0-.11-.16-.11H18.6a.12.12 0 0 0-.13.13v12.35c0 .07.05.12.16.13l2.21.21c.12 0 .18.05.18.14v1.68c0 .09-.06.13-.18.13h-9.68c-.16 0-.16 0-.16-.13v-1.68c0-.09.06-.14.18-.14l2.21-.21c.11 0 .16-.06.16-.13V10.33a.12.12 0 0 0-.13-.13h-4.5c-.11 0-.16 0-.16.11l-.18 2c0 .07-.05.11-.16.11H6.66"
                    transform="translate(0 -2)"
                  />
                  <path
                    fill="#fff"
                    d="M28 4a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h24m0-2H4a4.05 4.05 0 0 0-4 4v20a4.05 4.05 0 0 0 4 4h24a4.05 4.05 0 0 0 4-4V6a4.05 4.05 0 0 0-4-4z"
                    transform="translate(0 -2)"
                  />
                </svg>
              </a>
            </Link>
          </LinkItem>
        )}

        {!isMobile && (
          <LinkItem hideOnMobile>
            <Link prefetch={false} href="/lessons/" passHref legacyBehavior>
              <StyledLink title="Go to Lessons page">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  className=" u-margin-right-small u-vertAlign-mid"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M224 96l16-32 32-16-32-16-16-32-16 32-32 16 32 16 16 32zM80 160l26.66-53.33L160 80l-53.34-26.67L80 0 53.34 53.33 0 80l53.34 26.67L80 160zm352 128l-26.66 53.33L352 368l53.34 26.67L432 448l26.66-53.33L512 368l-53.34-26.67L432 288zm70.62-193.77L417.77 9.38C411.53 3.12 403.34 0 395.15 0c-8.19 0-16.38 3.12-22.63 9.38L9.38 372.52c-12.5 12.5-12.5 32.76 0 45.25l84.85 84.85c6.25 6.25 14.44 9.37 22.62 9.37 8.19 0 16.38-3.12 22.63-9.37l363.14-363.15c12.5-12.48 12.5-32.75 0-45.24zM359.45 203.46l-50.91-50.91 86.6-86.6 50.91 50.91-86.6 86.6z"></path>
                </svg>{' '}
                Lessons
              </StyledLink>
            </Link>
          </LinkItem>
        )}

        {!isMobile && (
          <LinkItem hideOnMobile>
            <Link prefetch={false} href="/stats/" passHref legacyBehavior>
              <StyledLink title="Go to Stats page">{statIcon} Stats</StyledLink>
            </Link>
          </LinkItem>
        )}

        <LinkItem style={{ marginLeft: 'auto' }} />

        <LinkItem hideOnMobile>
          <Button variant="secondary" short noPadding>
            <Link prefetch={false} href="/typing-practice/" passHref legacyBehavior>
              <TakeTestLink title="Go to Typing Practice page">Take a test</TakeTestLink>
            </Link>
          </Button>
        </LinkItem>

        <Loader
          placeholderHeight="1.5rem"
          placeholderWidth="121px"
          name="module-nav-button-loader"
          loading={authLoading}
        >
          {authUser.id && (
            <LinkItem>
              <Link prefetch={false} href="/profile/" passHref legacyBehavior>
                <StyledLink title="Go to Profile page">Profile</StyledLink>
              </Link>
            </LinkItem>
          )}

          {!authUser.id && (
            <LinkItem>
              <Link
                prefetch={false}
                href={`/login/${isArticlePage ? '' : `?destination=${encodeURIComponent(serverSyncedHref)}`}`}
                passHref
                legacyBehavior
              >
                <SignInSignUpLinkButton title="Go to Login page">Login</SignInSignUpLinkButton>
              </Link>
            </LinkItem>
          )}
          {!authUser.id && (
            <LinkItem>
              <Link
                prefetch={false}
                href={`/sign-up/${isArticlePage ? '' : `?destination=${encodeURIComponent(serverSyncedHref)}`}`}
                passHref
                legacyBehavior
              >
                <SignInSignUpLinkButton title="Go to Sign up page">Sign up</SignInSignUpLinkButton>
              </Link>
            </LinkItem>
          )}
        </Loader>

        <LinkItem hideOnMobile>
          <span ref={setTriggerRef} data-cy="module-nav-setting-icon">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              className="header__setting-icon"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path>
            </svg>
          </span>

          {visible && (
            <SettingTooltip
              ref={setTooltipRef}
              {...getTooltipProps({
                className: classnames('tooltip-container'),
              })}
            >
              <Setting signOut={signOut} authLoading={authLoading} />
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </SettingTooltip>
          )}
        </LinkItem>

        {/* only for development purpose let's show the theme toggler. Otherwise theme should be defined per the system preference */}
        {router.query.theme === 'true' && !isMobile && (
          <LinkItem>
            <Button
              short
              style={{ cursor: 'pointer' }}
              variant="secondary"
              onClick={() =>
                theme.togglePalette(theme.palette.mode === PaletteMode.dark ? PaletteMode.light : PaletteMode.dark, uid)
              }
            >
              &nbsp;☀&nbsp;
            </Button>
          </LinkItem>
        )}
      </Links>
    </Wrapper>
  )
}
