import styled, { css } from 'styled-components'

export const Main = styled.main`
  min-height: calc(100vh - 60px);
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down.md} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  `}
`
