import NextImage from 'next/image'

import { StyledLogo } from './Logo.styles'

export const Logo = () => {
  return (
    <StyledLogo>
      <NextImage src="/logo.png" height={21} width={121} alt="Go to typing practice page" />
    </StyledLogo>
  )
}
