// credit: https://www.netlify.com/blog/2020/12/05/building-a-custom-react-media-query-hook-for-more-responsive-apps/

import { useEffect, useState } from 'react'

/**
 * Usage: useMediaQuery(`screen and (max-width: ${theme.breakpoints.values.lg}px)`)
 * Please use "screen" as added above, without it safari throws error
 * @param query
 * @returns
 */
export const useMediaQuery = (query: string): boolean | undefined => {
  const [matches, setMatches] = useState<boolean | undefined>()

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    const listener = () => {
      setMatches(media.matches)
    }

    const legacy = typeof media.addEventListener !== 'function'

    if (!legacy) media.addEventListener('change', listener)
    if (legacy) media.addListener(listener)

    return () => {
      if (!legacy) media.removeEventListener('change', listener)
      if (legacy) media.removeListener(listener)
    }
  }, [matches, query])

  return matches
}
