import NextLink from 'next/link'
import React from 'react'
import { Button } from 'ui-kit/button'

import { InternalLinkProps, StyledLink } from './InternalLink.styles'

/**
 * InternalLink takes care of the passHref legacyBehavior=true
 * @param props
 * @returns
 */
const InternalLink = (props: InternalLinkProps) => {
  const { href = '', buttonView, children } = props
  const styledProps: InternalLinkProps = {
    ...props,
  }

  const preparedLink = () => (
    <NextLink href={href} passHref legacyBehavior={!!href} prefetch={false}>
      <StyledLink {...styledProps}>{children}</StyledLink>
    </NextLink>
  )

  return (
    <>
      {href && (
        <>
          {buttonView && (
            <Button noPadding {...buttonView}>
              {preparedLink()}
            </Button>
          )}
          {!buttonView && preparedLink()}
        </>
      )}

      {!href && <span>{children}</span>}
    </>
  )
}

export { InternalLink }
