import styled, { css } from 'styled-components'
import { ButtonProps } from 'ui-kit/button/interface'

export interface InternalLinkProps {
  children: React.ReactNode
  href?: string
  title?: string
  className?: string
  target?: string
  onClick?: () => void
  buttonView?: ButtonProps
}

export const StyledLink = styled.a<InternalLinkProps>`
  cursor: pointer;
  text-decoration: none;
  transition: color 0.1s ease-in;

  ${({ buttonView }) =>
    buttonView &&
    css`
      color: inherit;
      border: 1px solid gray;
      height: 100%;
      border-radius: inherit;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
      min-width: 100%;
    `}
`
