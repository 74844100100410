import React, { ReactNode } from 'react'
import { InternalLink } from 'ui-kit/internal-Link'

interface FooterLinkProps {
  href?: string
  children: ReactNode
  title: string
  wrapperClass?: string
}

export const FooterLink = (props: FooterLinkProps) => {
  const { href, children, title, wrapperClass } = props
  return (
    <InternalLink className={wrapperClass} href={href} title={title}>
      {children}
    </InternalLink>
  )
}
