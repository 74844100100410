export const IntellixioUrl = 'https://www.intellixio.com/'
export const TypingPracticeUrl = '/typing-practice/'
export const FbUrl = 'https://www.facebook.com/typingmentor'
export const TwitterUrl = 'https://twitter.com/typingmentor'
export const ContactsUs = '/contact-us/'
export const AboutUrl = '/about/'
export const DiscordServerUrl = 'https://discord.com/invite/FM2CYQ2'
export const ForumUrl = 'https://groups.google.com/g/typing-mentor?pli=1'
export const WhyTypingMentorUrl = '/why-typingmentor/'
export const FeaturesUrl = '/features/'
export const ParentGuideUrl = '/parent-guide/'
export const HelpSupportUrl = '/help/'
export const TermsUrl = '/terms-of-service/'
export const PrivacyUrl = '/privacy/'
export const LessonsUrl = '/lessons/'
export const StatsUrl = '/stats/'
export const KnowledgeUrl = '/knowledge/'
