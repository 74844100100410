import { Loader } from 'components/loader'
import { useAppDispatch } from 'hooks/use-app-dispatch'
import { useAppSelector } from 'hooks/use-app-selector'
import { DB_REF } from 'interface/database'
import dynamic from 'next/dynamic'
import { AuthUser, useAuthUser } from 'next-firebase-auth'
import { useCallback } from 'react'
import { selectApp, settingSlice } from 'store/slices/setting'
import { Button } from 'ui-kit/button'
import { Toggle } from 'ui-kit/toggle'

import {
  AppSettings,
  DividerBottom,
  DividerTop,
  SettingsItem,
  ToggleOption,
  TypingAreaSettings,
  Wrapper,
} from './Setting.styles'

const DurationDynamic = dynamic<unknown>(() => import('./components/duration').then((mod) => mod.Duration))

export const Setting = ({ signOut, authLoading }: { signOut?: AuthUser['signOut']; authLoading?: boolean }) => {
  const authUser = useAuthUser()
  const setting = useAppSelector(selectApp)
  const dispatch = useAppDispatch()

  // duration functionality hasn't been implemented fully, so currently duration is not working.
  // Therefore it is set to hardcoded false
  const isDurationBased = false

  const handleShowLiveStat = useCallback(() => {
    dispatch(
      settingSlice.actions.setShowLiveStat({
        uid: authUser.id,
        dbRef: DB_REF.showLiveStat,
        data: !setting.showLiveStat,
      })
    )
  }, [authUser.id, dispatch, setting.showLiveStat])

  const handleShowLiveWordPerf = useCallback(() => {
    dispatch(
      settingSlice.actions.setShowLiveWordPerf({
        uid: authUser.id,
        dbRef: DB_REF.showLiveWordPerf,
        data: !setting.showLiveWordPerf,
      })
    )
  }, [authUser.id, dispatch, setting.showLiveWordPerf])

  const handleShowProgressBar = useCallback(() => {
    dispatch(
      settingSlice.actions.setShowProgressBar({
        uid: authUser.id,
        dbRef: DB_REF.showProgressBar,
        data: !setting.showProgressBar,
      })
    )
  }, [authUser.id, dispatch, setting.showProgressBar])

  const handleShowTypingHands = useCallback(() => {
    dispatch(
      settingSlice.actions.setShowTypingHands({
        uid: authUser.id,
        dbRef: DB_REF.showTypingHands,
        data: !setting.showTypingHands,
      })
    )
  }, [authUser.id, dispatch, setting.showTypingHands])

  return (
    <Wrapper>
      {isDurationBased && (
        <AppSettings>
          <SettingsItem>
            <div>Test duration</div>&nbsp;
            <DurationDynamic />
            &nbsp;
            <div>seconds</div>
          </SettingsItem>
        </AppSettings>
      )}

      <TypingAreaSettings>
        <DividerTop />
        <ToggleOption data-cy="setting-toggle-option-show-live-stat">
          <div>Show live stats</div>
          <Toggle isOn={setting.showLiveStat} onClick={handleShowLiveStat} />
        </ToggleOption>

        <ToggleOption data-cy="setting-toggle-option-show-typing-hands">
          <div>Show hands</div>
          <Toggle isOn={setting.showTypingHands} onClick={handleShowTypingHands} />
        </ToggleOption>

        <ToggleOption data-cy="setting-toggle-option-show-live-word-perf">
          <div>Show live word performance</div>
          <Toggle isOn={setting.showLiveWordPerf} onClick={handleShowLiveWordPerf} />
        </ToggleOption>

        <ToggleOption data-cy="setting-toggle-option-show-progress-bar">
          <div>Show progress bar</div>
          <Toggle isOn={setting.showProgressBar} onClick={handleShowProgressBar} />
        </ToggleOption>

        <DividerBottom />
      </TypingAreaSettings>

      {authUser.id && (
        <Button
          short
          onClick={() => {
            signOut?.().then(() => {
              window.location.reload()
            })
          }}
        >
          <Loader name="module-setting-logout" loading={!!authLoading}>
            Logout
          </Loader>
        </Button>
      )}
    </Wrapper>
  )
}
