import { StyleGuide } from './interface'

export const defaultConfig: StyleGuide = {
  height: 24,
  width: 44,
  borderWidth: 0,
  borderColorOn: '#777',
  borderColorOff: '#777',
  bgOn: '#00C48C',
  bgOff: '#aaa',
  ballBgOn: 'white',
  ballBgOff: 'white',
  toggleDuration: 300,
}

export const variantMapping: { [variantName: string]: StyleGuide } = {
  default: defaultConfig,
}
