import styled, { css } from 'styled-components'

import { DividerProps } from './Divider'

export const getWrapperHeight = (args: DividerProps) => {
  const { isVertical, isHorizontal, wrapperThickness } = args
  switch (true) {
    case isVertical:
      return '100%'

    case isHorizontal:
      return wrapperThickness

    default:
      break
  }
  return ''
}

export const getWrapperWidth = (args: DividerProps) => {
  const { isVertical, isHorizontal, wrapperThickness } = args
  switch (true) {
    case isVertical:
      return wrapperThickness

    case isHorizontal:
      return '100%'

    default:
      break
  }
  return ''
}

export const getBarHeight = (args: DividerProps) => {
  const { isVertical, isHorizontal, length = '100%' } = args
  switch (true) {
    case isVertical:
      return length

    case isHorizontal:
      return '2px'

    default:
      break
  }
  return ''
}

export const getBarWidth = (args: DividerProps) => {
  const { isVertical, isHorizontal, length = '100%' } = args
  switch (true) {
    case isVertical:
      return '2px'

    case isHorizontal:
      return length

    default:
      break
  }
  return ''
}
export const getBorder = (args: DividerProps) => {
  const { isVertical, isHorizontal, barThickness, color } = args
  switch (true) {
    case isVertical:
      return css`
        border-left: ${barThickness} solid ${color};
      `

    case isHorizontal:
      return css`
        border-top: ${barThickness} solid ${color};
      `

    default:
      break
  }
  return ''
}

export const getPosition = (args: DividerProps) => {
  const { positionAt } = args
  const xPos = (positionAt && positionAt[0]) || '50%'
  const yPos = (positionAt && positionAt[1]) || '50%'

  return css`
    top: ${yPos};
    left: ${xPos};
  `
}

export const DividerWrapper = styled.div<DividerProps>`
  background: ${({ wrapperBg }) => wrapperBg};
  height: ${(props) => getWrapperHeight(props)};
  width: ${(props) => getWrapperWidth(props)};
  position: relative;
  flex-shrink: 0;
`

export const Bar = styled.div<DividerProps>`
  box-sizing: border-box;
  background-color: transparent;
  height: ${(props) => getBarHeight(props)};
  width: ${(props) => getBarWidth(props)};
  ${(props) => getBorder(props)}
  position: absolute;
  ${(props) => getPosition(props)};
  transform: translate(-50%, -50%);
`
