import { PAGES } from 'constants/routes'
import { SITE_METADATA } from 'constants/site'
import { ArticleJsonLd, BreadcrumbJsonLd, NextSeo } from 'next-seo'
import { urlCleanDuplicateSlash } from 'utils/url-clean-duplicate-slash'
import { urlWithSiteOrigin } from 'utils/url-with-site-origin'

export interface SeoMeta {
  name?: string
  key?: string
  content?: string
  property?: string
}

export interface SeoProps {
  description?: string
  title?: string
  thumbnail?: string
  // make sure canonicalUrl to be absolute url and ends with slash
  canonicalUrl?: string
  pageUrl?: string
  datePublished?: string
  dateModified?: string
  authorName?: string
  noIndex?: boolean
  noFollow?: boolean
}

export const Seo = ({
  // make sure canonicalUrl to be absolute url and ends with slash
  canonicalUrl,
  description = '',
  title,
  thumbnail,
  datePublished,
  dateModified,
  authorName,
  noIndex,
  noFollow,
  pageUrl,
}: SeoProps) => {
  const { defaultTitle, defaultDescription, name: siteName, siteUrl } = SITE_METADATA

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: pageUrl || siteUrl,
    image: thumbnail || 'https://typingmentor.com/typing-mentor-og-image.png',
    datePublished: datePublished ? new Date(datePublished).toISOString() : '2019-01-01T10:00:00.000Z',
    dateModified: dateModified ? new Date(dateModified).toISOString() : '2019-01-01T10:00:00.000Z',
    authorName: authorName || siteName,
  }

  const derivedType = (canonicalUrl || '').includes('/articles/') ? 'article' : 'website'
  const isArticle = derivedType === 'article'

  return (
    <>
      <NextSeo
        noindex={!!noIndex}
        nofollow={!!noFollow}
        canonical={canonicalUrl}
        title={seo.title}
        description={seo.description}
        openGraph={{
          url: pageUrl,
          title: seo.title,
          description: seo.description,
          ...(isArticle
            ? {
                type: 'article',
                article: {
                  publishedTime: seo.datePublished,
                  modifiedTime: seo.dateModified,
                  // expirationTime: '2022-12-21T22:04:11Z',
                  // section: 'Section II',
                  authors: [
                    // TODO: FIX it by providing author page link, but for now I am using pageUrl
                    pageUrl || siteUrl,
                    // 'https://www.example.com/authors/@firstnameA-lastnameA',
                    // 'https://www.example.com/authors/@firstnameB-lastnameB',
                  ],
                  // tags: ['Tag A', 'Tag B', 'Tag C'],
                },
              }
            : {}),
          images: [
            {
              url: seo.image,
              width: 1200,
              height: 630,
              alt: 'Og Image Alt',
              type: 'image/jpeg',
            },
            // {
            //   url: 'https://www.example.ie/og-image-01.jpg',
            //   width: 800,
            //   height: 600,
            //   alt: 'Og Image Alt',
            //   type: 'image/jpeg',
            // },
            // {
            //   url: 'https://www.example.ie/og-image-02.jpg',
            //   width: 900,
            //   height: 800,
            //   alt: 'Og Image Alt Second',
            //   type: 'image/jpeg',
            // },
            // { url: 'https://www.example.ie/og-image-03.jpg' },
            // { url: 'https://www.example.ie/og-image-04.jpg' },
          ],
          siteName,
        }}
        twitter={{
          // @author_handle
          handle: '@typingmentor',

          // @publisher_handle
          site: '@typingmentor',
          cardType: 'summary_large_image',
        }}
      />

      {isArticle && (
        <>
          <ArticleJsonLd
            url={seo.url}
            title={seo.title}
            images={[
              seo.image,
              // 'https://example.com/photos/1x1/photo.jpg',
              // 'https://example.com/photos/4x3/photo.jpg',
              // 'https://example.com/photos/16x9/photo.jpg',
            ]}
            datePublished={seo.datePublished}
            dateModified={seo.dateModified}
            authorName={[
              {
                name: seo.authorName,
                url: pageUrl,
              },
              // {
              //   name: 'Mary Stone',
              //   url: 'https://example.com',
              // },
            ]}
            publisherName={siteName}
            publisherLogo={urlCleanDuplicateSlash(`${siteUrl}/logo-square.png`)}
            description={seo.description}
            isAccessibleForFree={true}
          />

          <BreadcrumbJsonLd
            itemListElements={[
              {
                position: 1,
                name: 'Home',
                item: siteUrl,
              },
              {
                position: 2,
                name: 'Articles',
                item: urlWithSiteOrigin(PAGES['/articles/']),
              },
              {
                position: 3,
                name: seo.title,
                item: pageUrl,
              },
            ]}
          />
        </>
      )}
    </>
  )
}
