import React, { forwardRef } from 'react'

import { ButtonStyled } from './Button.styles'
import { ButtonProps } from './interface'

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, wrapperClass, variant } = props

  return (
    <ButtonStyled {...props} ref={ref} className={wrapperClass} variant={variant}>
      {children}
    </ButtonStyled>
  )
})

Button.displayName = 'Button'

export { Button }
