import styled from 'styled-components'

export const FooterWrapper = styled.div`
  width: 100%;
  font-family: Ubuntu, sans-serif;
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: relative;
  z-index: 0;

  @media (max-width: 64rem) {
    padding: 0 20px;
  }

  a {
    color: hsla(0, 0%, 100%, 0.9);

    &:hover {
      color: ${({ theme }) => theme.palette.primary.contrastText};
    }
  }
`

export const FooterTop = styled.div`
  padding-top: 80px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;

  @media (max-width: 64rem) {
    flex-direction: column;
    padding: 2rem 1.25rem;
    justify-content: normal;
  }
`

export const FooterTopLeft = styled.div`
  max-width: 320px;
  color: #fff;
  margin-right: 50px;
  color: hsla(0, 0%, 100%, 0.75);

  @media (max-width: 64rem) {
    margin-bottom: 5rem;
    margin-right: 0;
    align-self: center;
    text-align: center;
  }
`

export const FooterTopRight = styled.div`
  max-width: 640px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 64rem) {
    max-width: 100%;
  }

  @media (max-width: 43.75rem) {
    flex-wrap: wrap;
    padding: 0 3.125rem;
  }
`

export const FooterTopRightColumn = styled.div`
  font-size: 16px;
  color: hsla(0, 0%, 100%, 0.75);

  & > * {
    display: block;
  }

  &:not(:last-child) {
    margin-right: 32px;

    @media (max-width: 64rem) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  & > *:not(:last-child) {
    margin-bottom: 18px;
  }

  @media (max-width: 43.75rem) {
    min-width: 50%;
    text-align: center;

    & > button {
      margin: 0 auto;
    }
  }

  @media (max-width: 31.25rem) {
    min-width: 100%;
  }
`

export const FooterTopRightColumnHeader = styled.div`
  font-weight: bold;
  color: white;
`

export const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  font-size: 15px;
  color: hsla(0, 0%, 100%, 0.75);
  max-width: 1024px;
  margin: 0 auto;

  &::after {
    content: '';
    height: 88px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    /* //HIGHLIGHT: for z-index */
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.45);
  }

  @media (max-width: 43.75rem) {
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    height: 140px;

    &::after {
      height: 140px;
    }
  }
`

export const FooterBottomLeft = styled.div`
  display: flex;
  align-items: center;
`

export const FooterBottomMiddle = styled.div`
  svg {
    vertical-align: middle;
  }

  @media (max-width: 43.75rem) {
    order: -2;
  }
`

export const FooterBottomRight = styled.div`
  display: flex;
  align-items: center;

  div {
    vertical-align: middle !important;
  }

  @media (max-width: 43.75rem) {
    order: -1;
  }
`
