import { variantMapping } from './config'
import { GetStyling, ToggleProps } from './interface'

export const getStylingProps = (toggleProps: ToggleProps): GetStyling => {
  const { isOn, variant = 'default' } = toggleProps
  const {
    height,
    width,
    borderWidth,
    borderColorOff,
    borderColorOn,
    bgOn,
    bgOff,
    ballBgOn,
    ballBgOff,
    toggleDuration,
  } = variantMapping[variant]

  const ballToBorderDistance = height * 0.12
  const ballHeight = height - ballToBorderDistance * 2 - borderWidth * 2
  const ballWidth = ballHeight
  const innerWidth = width - borderWidth * 2
  const ballLeftWhenIsOn = innerWidth - ballWidth - ballToBorderDistance
  const ballLeftWhenIsOff = ballToBorderDistance

  return {
    ballWidth,
    ballHeight,
    borderWidth,
    height,
    width,
    isOn,
    toggleDuration,
    bg: isOn ? bgOn : bgOff,
    ballBg: isOn ? ballBgOn : ballBgOff,
    borderColor: isOn ? borderColorOn : borderColorOff,
    ballLeftPos: isOn ? ballLeftWhenIsOn : ballLeftWhenIsOff,
  }
}
