import styled from 'styled-components'

import { GetStyling } from './interface'

export const ToggleWrapper = styled.div<GetStyling>`
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  border-radius: 99rem;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ bg }) => bg};
  border: ${({ borderWidth, borderColor }) => `${borderWidth}px solid ${borderColor}`};
  transition: ${({ toggleDuration }) => `${toggleDuration}ms all`};

  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 99rem;
    top: 50%;
    transform: translateY(-50%);
    transition: ${({ toggleDuration }) => `${toggleDuration}ms all`};
    height: ${({ ballHeight }) => `${ballHeight}px`};
    width: ${({ ballWidth }) => `${ballWidth}px`};
    left: ${({ ballLeftPos }) => `${ballLeftPos}px`};
    background: ${({ ballBg }) => ballBg};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.149749);
  }
`
