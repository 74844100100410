import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.default};

  .tooltip-container {
    padding: 1rem;
  }

  .header__setting-icon {
    font-size: 1.625rem;
    color: #fff;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
      transform: rotate(45deg);
    }
  }

  @media (max-width: 64rem) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

export const Links = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  height: 3.75rem;

  & > * + * {
    margin-left: 1.75rem;
  }

  @media (max-width: 64rem) {
    & > * + * {
      margin-left: 0.75rem;
    }
  }
`

// hideOnMobile boolean is needed to avoid CLS on mobile. But do not use hideOnMobile for hiding it
// from BOT, you need to use conditional JS rendering for actually removing it from DOM
export const LinkItem = styled.li<{ hideOnMobile?: boolean }>`
  ${({ theme, hideOnMobile }) =>
    hideOnMobile &&
    css`
      ${theme.breakpoints.down.sm} {
        display: none;
      }
    `};
`

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};

  & > svg {
    margin-right: 0.25rem;
  }
`

export const Menu = styled.ul`
  display: none;
  list-style: none;
  padding: 1rem;
  position: absolute;
  top: 3.5rem;
  left: -0.625rem;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
  box-shadow: ${({ theme }) => theme.palette.shadow.main};
  background-color: ${({ theme }) => theme.palette.primary.main};
  z-index: ${({ theme }) => theme.zIndex.snackbar};

  & > :not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

export const Hamburger = styled.li`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  display: none;
  position: relative;
  align-self: stretch;

  @media (max-width: 43.75rem) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover ${Menu} {
    display: block;
  }
`

export const SignInSignUpLinkButton = styled.a`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  cursor: pointer;
`

export const SettingTooltip = styled.span`
  display: block;
`
