// credit: https://github.com/streamich/react-use/blob/master/src/useTimeoutFn.ts

import { useCallback, useEffect, useRef } from 'react'

export type UseTimeoutFnReturn = [() => boolean | null, () => void, () => void]

/**
 * Very similar to the useInterval hook, this React hook implements the native
 * setTimeout function keeping the same interface. You can enable the timeout
 * by setting delay as a number or disabling it using null. When the time
 * finishes, the callback function is called.
 * @param fn
 * @param ms
 * @returns
 */

// eslint-disable-next-line @typescript-eslint/ban-types
function useTimeoutFn(fn: Function, ms = 0): UseTimeoutFnReturn {
  const ready = useRef<boolean | null>(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>()
  const callback = useRef(fn)

  const isReady = useCallback(() => ready.current, [])

  const set = useCallback(() => {
    ready.current = false
    timeout.current && clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      ready.current = true
      callback.current()
    }, ms)
  }, [ms])

  const clear = useCallback(() => {
    ready.current = null
    timeout.current && clearTimeout(timeout.current)
  }, [])

  // update ref when function changes
  useEffect(() => {
    callback.current = fn
  }, [fn])

  // set on mount, clear on unmount
  useEffect(() => {
    set()

    return clear
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms])

  return [isReady, clear, set]
}

export { useTimeoutFn }
