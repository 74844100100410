import * as React from 'react'

import { Bar, DividerWrapper } from './Divider.styles'

export interface DividerProps {
  isHorizontal?: boolean
  isVertical?: boolean
  wrapperThickness?: string
  barThickness?: string
  length?: string
  noBar?: boolean
  positionAt?: string[]
  color?: string
  wrapperBg?: string
}

export const Divider = (props: DividerProps) => {
  const {
    isHorizontal = true,
    isVertical = false,
    wrapperThickness = '2rem',
    barThickness = '1px',
    length = '100%',
    noBar = false,
    positionAt = ['50%', '50%'],
    color = '#aaa',
    wrapperBg = 'transparent',
  } = props

  const styledProps = {
    isHorizontal,
    isVertical,
    wrapperThickness,
    barThickness,
    length,
    noBar,
    positionAt,
    color,
    wrapperBg,
  }

  return <DividerWrapper {...styledProps}>{!noBar && <Bar {...styledProps} />}</DividerWrapper>
}
