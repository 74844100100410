import { FlashingDots } from 'components/loader/components/flashing-dots'
import { Spinner } from 'components/loader/components/spinner'
import { IS_DEV } from 'constants/app'
import { SentryPageTag } from 'constants/sentry'
import { useOffline } from 'context-providers/offline-provider'
import { useTimeoutFn } from 'hooks/use-timeout-fn'
import { ReactNode, useCallback, useEffect, useRef } from 'react'
import { callSentry } from 'utils/sentry/call-sentry'

import { AbsoluteWrapper, FullPageWrapper, InlineWrapper, Placeholder } from './Loader.styles'

export interface LoaderProps {
  loading: boolean
  // name of the component that is loading
  name: string
  children?: ReactNode
  variant?: 'inline' | 'fullPage' | 'absolute'
  // size=1 is default, but you can pass any number, e.g. size=2 doubles the default size
  size?: number
  placeholderHeight?: string
  placeholderWidth?: string
}

export const Loader = (props: LoaderProps) => {
  const { children = null, loading, variant = 'inline', size, name, placeholderHeight, placeholderWidth } = props

  const timeTracker = useRef<number>(0)
  const isOffline = useOffline()

  useEffect(() => {
    if (loading) {
      timeTracker.current = Date.now()
    }
  }, [loading])

  const callSentryIfNotLoaded = useCallback(() => {
    if (loading && !isOffline) {
      if (IS_DEV) {
        // eslint-disable-next-line no-console
        console.log({ loaderName: name })
      }

      // TODO: Remove this (10%) if condition when we have a better way to track the loading time
      // to decrease the sentry event made it 8 seconds for now
      if (Math.random() < 0.1) {
        callSentry({
          pageTag: SentryPageTag.loader,
          // to decrease the sentry event made it 8 seconds for now
          message: `${name} took 8 seconds but not loaded yet.`,
          toastMessage:
            'Sorry for the delayed loading. Please reload the page if the problem persists. Support team is working on it.',
        })
      }
    }
  }, [isOffline, loading, name])

  useTimeoutFn(callSentryIfNotLoaded, 8000)

  if (!loading) {
    return <>{children}</>
  }

  let spinner = variant === 'inline' ? <FlashingDots /> : <Spinner />

  if (placeholderHeight !== undefined || placeholderWidth !== undefined) {
    spinner = (
      <Placeholder height={placeholderHeight} width={placeholderWidth}>
        {spinner}
      </Placeholder>
    )
  }

  switch (variant) {
    case 'fullPage':
      return <FullPageWrapper size={size}>{spinner}</FullPageWrapper>

    case 'absolute':
      return <AbsoluteWrapper size={size}>{spinner}</AbsoluteWrapper>

    default:
    case 'inline':
      return <InlineWrapper size={size}>{spinner}</InlineWrapper>
  }
}
