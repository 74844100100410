import 'react-popper-tooltip/dist/styles.css'

import { Loader } from 'components/loader'
import { Seo } from 'components/seo'
import { SITE_METADATA } from 'constants/site'
import { Footer } from 'module/footer'
import { Nav } from 'module/nav'

import { LayoutProps } from './interface'
import { Main } from './Layout.styles'

export const Layout = ({
  children,
  signOut,
  uid,
  authLoading = false,
  fullPageAuthLoader,
  seoProps = {
    title: SITE_METADATA.defaultTitle,
    description: SITE_METADATA.defaultDescription,
  },
}: LayoutProps) => {
  return (
    <>
      <Seo {...seoProps} />

      <Nav authLoading={authLoading} uid={uid} signOut={signOut} />

      <Loader loading={!!fullPageAuthLoader && authLoading} name="layout-full-page-auth-loader" variant="fullPage">
        <Main>{children}</Main>
      </Loader>
      <Footer />
    </>
  )
}
