import styled from 'styled-components'

export const Wrapper = styled.div``

export const AppSettings = styled.div`
  .SettingsInput {
    border: 1px solid #bbb;
    width: 3.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    outline: none;
  }
  .SettingsCheck {
    height: 0.875rem;
    width: 0.875rem;
    border-radius: 3px;
    border: 1px solid #bbb;
  }
`

export const SettingsItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .invalid-duration {
    border: 1px solid ${({ theme }) => theme.palette.error.main};
  }
`
export const TypingAreaSettings = styled.div``

export const DividerTop = styled.div`
  margin-top: 0.75rem;
`

export const DividerBottom = styled.div`
  margin-bottom: 1rem;
`

export const ToggleOption = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    min-width: 14.5rem;
    margin-right: 1rem;
  }

  margin-bottom: 0.5rem;
`
