import { PG } from 'constants/pg'
import { PAGES } from 'constants/routes'
import { useAppDispatch } from 'hooks/use-app-dispatch'
import Link from 'next/link'
import React from 'react'
import { pgSlices } from 'store/slices/pg-slice'
import { Button, TakeTestLink } from 'ui-kit/button'
import { Divider } from 'ui-kit/divider'
import { InternalLink } from 'ui-kit/internal-Link'

import { FooterLink } from './components/footer-link'
import { FooterLogo } from './components/footer-logo'
import { FooterLinkText } from './components/footer-text-link'
import {
  AboutUrl,
  ContactsUs,
  DiscordServerUrl,
  FbUrl,
  FeaturesUrl,
  ForumUrl,
  HelpSupportUrl,
  IntellixioUrl,
  KnowledgeUrl,
  LessonsUrl,
  ParentGuideUrl,
  PrivacyUrl,
  StatsUrl,
  TermsUrl,
  TwitterUrl,
  WhyTypingMentorUrl,
} from './constants'
import {
  FooterBottom,
  FooterBottomLeft,
  FooterBottomMiddle,
  FooterBottomRight,
  FooterTop,
  FooterTopLeft,
  FooterTopRight,
  FooterTopRightColumn,
  FooterTopRightColumnHeader,
  FooterWrapper,
} from './Footer.styles'

export const Footer = () => {
  const mainPgSlice = pgSlices[PG.MAIN_PG]
  const dispatch = useAppDispatch()
  return (
    <FooterWrapper>
      <FooterTop>
        <FooterTopLeft>
          <FooterLogo />
          <Divider wrapperThickness="3rem" color="rgba(255, 255, 255, 0.25)" positionAt={['50%', '55%']} />
          <p>
            Let us know any query or suggestion you have in your mind&nbsp;
            <FooterLinkText title="Go to Contact Us page" href={ContactsUs}>
              here.
            </FooterLinkText>
          </p>
        </FooterTopLeft>
        <FooterTopRight>
          <FooterTopRightColumn>
            <FooterTopRightColumnHeader>Company</FooterTopRightColumnHeader>

            <FooterLink title="Go to about page" href={AboutUrl}>
              About
            </FooterLink>
            <FooterLink title="Go to  Discord Server" href={DiscordServerUrl}>
              Discord Server
            </FooterLink>
            <FooterLink title="Go to Forum page" href={ForumUrl}>
              Forum
            </FooterLink>
            <FooterLink title="Go to Contact us page" href={ContactsUs}>
              Contact us
            </FooterLink>
          </FooterTopRightColumn>
          <FooterTopRightColumn>
            <FooterTopRightColumnHeader>Product</FooterTopRightColumnHeader>

            <FooterLink title="Go to  Why TypingMentor page" href={WhyTypingMentorUrl}>
              Why TypingMentor?
            </FooterLink>
            <FooterLink title="Go to Features page" href={FeaturesUrl}>
              Features
            </FooterLink>
            <FooterLink
              wrapperClass="gtm-ask-chat-gpt-beta-footer-link"
              title="Go to TypingMentor AI Beta Sign-up page"
              href={PAGES['/ask-chat-gpt/']}
            >
              TypingMentor AI (Beta)
            </FooterLink>
          </FooterTopRightColumn>
          <FooterTopRightColumn>
            <FooterTopRightColumnHeader>Resources</FooterTopRightColumnHeader>

            <FooterLink title="Go to Help & Support page" href={HelpSupportUrl}>
              Help & Support
            </FooterLink>
            <FooterLink title="Go to Terms page" href={TermsUrl}>
              Terms
            </FooterLink>
            <FooterLink title="Go to Privacy page" href={PrivacyUrl}>
              Privacy
            </FooterLink>
            <FooterLink title="Go to Parent Guide page" href={ParentGuideUrl}>
              Parent Guide
            </FooterLink>
          </FooterTopRightColumn>
          <FooterTopRightColumn>
            <FooterTopRightColumnHeader>TypingMentor</FooterTopRightColumnHeader>

            <FooterLink title="Go to Lessons page" href={LessonsUrl}>
              Lessons
            </FooterLink>
            <FooterLink title="Go to Stats page" href={StatsUrl}>
              Stats
            </FooterLink>
            <FooterLink title="Go to Knowledge base page" href={KnowledgeUrl}>
              Knowledge Base
            </FooterLink>

            <Button noPadding short variant="secondary">
              <Link href="/typing-practice/" passHref legacyBehavior prefetch={false}>
                <TakeTestLink title="Go to Typing Practice page">Take a test</TakeTestLink>
              </Link>
            </Button>
          </FooterTopRightColumn>
        </FooterTopRight>
      </FooterTop>
      <FooterBottom>
        <FooterBottomLeft>
          Made with&nbsp;
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            className="u-vertAlign-mid"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
          </svg>
          &nbsp;by&nbsp;
          <InternalLink title="Go to Intellixio Website" href={IntellixioUrl}>
            Intellixio
          </InternalLink>
        </FooterBottomLeft>
        <FooterBottomMiddle>
          {/* Go to home page */}
          <InternalLink title="go to home page" href="/">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="32" height="28">
              <defs></defs>
              <path
                fill="#fff"
                d="M6.5 12.46A53.65 53.65 0 0 1 6.66 7h18.68a11.7 11.7 0 0 1 .16 3.09v2.36h-1.42c-.11 0-.66 0-.66-.11l-.18-2c0-.07 0-.11-.16-.11H18.6a.12.12 0 0 0-.13.13v12.35c0 .07.05.12.16.13l2.21.21c.12 0 .18.05.18.14v1.68c0 .09-.06.13-.18.13h-9.68c-.16 0-.16 0-.16-.13v-1.68c0-.09.06-.14.18-.14l2.21-.21c.11 0 .16-.06.16-.13V10.33a.12.12 0 0 0-.13-.13h-4.5c-.11 0-.16 0-.16.11l-.18 2c0 .07-.05.11-.16.11H6.66"
                transform="translate(0 -2)"
              />
              <path
                fill="#fff"
                d="M28 4a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h24m0-2H4a4.05 4.05 0 0 0-4 4v20a4.05 4.05 0 0 0 4 4h24a4.05 4.05 0 0 0 4-4V6a4.05 4.05 0 0 0-4-4z"
                transform="translate(0 -2)"
              />
            </svg>
          </InternalLink>
        </FooterBottomMiddle>
        <FooterBottomRight>
          follow us&nbsp;
          <InternalLink title="Go to TypingMentor Facebook Page" href={FbUrl}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 320 512"
              className="u-vertAlign-mid u-margin-leftRight-small"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
            </svg>
          </InternalLink>{' '}
          &nbsp;
          <InternalLink title="Go to TypingMentor Twitter Page " href={TwitterUrl}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              className="u-vertAlign-mid"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
            </svg>
          </InternalLink>
        </FooterBottomRight>
      </FooterBottom>
    </FooterWrapper>
  )
}
