import React, { ReactElement, ReactNode } from 'react'
import { InternalLink } from 'ui-kit/internal-Link'

import { Text } from './FooterLinkText.styles'

interface FooterLinkTextProps {
  href?: string
  children: ReactNode
  title: string
}

export const FooterLinkText = (props: FooterLinkTextProps): ReactElement => {
  const { href, children, title } = props
  return (
    <InternalLink title={title} href={href}>
      <Text>{children}</Text>
    </InternalLink>
  )
}
