import { SITE_URL_ORIGIN } from 'constants/site'
import { urlCleanDuplicateSlash } from 'utils/url-clean-duplicate-slash'

/**
 * it returns the non-WWW version of the url. Note, query params or hash tags are not included if args.path doesn't have it.
 * @param path
 * @returns
 */
export const urlWithSiteOrigin = (path: string) => {
  return urlCleanDuplicateSlash(`${SITE_URL_ORIGIN}/${path}`)
}
